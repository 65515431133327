export function format(first: string, middle: string, last: string): string {
  return (first || '') + (middle ? ` ${middle}` : '') + (last ? ` ${last}` : '');
}

export function capitalizeFirstLetter(string: string): string {
  return string[0].toUpperCase() + string.slice(1);
}

export function formatedPrice(price: number): string {
  return new Intl.NumberFormat("pt-BR", { style: 'currency', currency: 'BRL', maximumFractionDigits: 2 }).format(price).replace(/\u00a0/g, ' ');
}

export function debounce(func, delay: number) {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;

    clearTimeout(timeout);

    timeout = setTimeout(() => {
      if(typeof func === 'function') {
        func.apply(context, args);
      }
    }, delay);
  };
}

export function hexToRgb(hex: string) {
  const validHex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const red = validHex && parseInt(validHex[1], 16);
  const green = validHex && parseInt(validHex[2], 16);
  const blue = validHex && parseInt(validHex[3], 16);

  return validHex ? `${red},${green},${blue}` : '0,0,0';
}

export function scrollToTop(selector: string) {
  document.querySelector(selector).scrollTo(0,0);
}

export function isOnIframe() {
  return window.frameElement !== null;
}

export function valueToLowerCaseForDataTestId(label: string) {
  const removeWhiteSpacesAndSpecialCharactersRegex = /[\s:,.;?\/!@#$%^&*()_+={}[\]<>|~´`]+/g;
  const removeExtrasDashRegex = /[-]{2}/g
  const formatedValue = label
    .replace(removeWhiteSpacesAndSpecialCharactersRegex, '-')
    .replace(removeExtrasDashRegex, '-')
    .toLowerCase();
  return formatedValue;
}

export function getSkeletonWidth (
  labelSize: number,
  intervals: {
    max: number;
    w: number;
  }[],
  fixedWidth: number,
)  {
  const skeletonWidth = intervals.find(interval => labelSize >= interval.max)
  return skeletonWidth ? skeletonWidth.w : fixedWidth
}